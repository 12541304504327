<template>
    <div class="week-profit">
        <el-card>
            <div slot="header" class="clearfix">
                <span>每周盈亏</span>
                <date-range style="float: right" v-on:change="dateRangeChange"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：统计每个交易周的盈亏情况。</p>
                <p>如何使用：点击某周矩形柱，即可开启笔记功能，记录当周的操作心得，如能做到连续每周盈利也是非常了不起的一件事情哩。建议每周查看使用。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  //每月盈亏
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "weekProfit",
    data () {
      return {
        starLevel: 4,
        options: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 5,
              depth: 30,
              viewDistance: 90
            },
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: false,
          colors: ['#ff2d2e'],
          plotOptions: {
            series: {
              negativeColor: '#00933b'
            }
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            title: false,
          },
          series: [],
        }
      }
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('weekProfit', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let categories = []
          let f1s = []
          Array.isArray(resData) && resData.map(item => {
            categories.push(item.yw)
            if (item.f1 || item.f1 === 0) {
              f1s.push(item.f1)
            }
          })
          this.options.xAxis.categories = categories;
          this.options.series = [{
            name: '周盈亏',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      },
      dateRangeChange () {
        this.getData();
      }
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .week-profit {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
